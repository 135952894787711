body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
  margin: 0;
  width: 100%;
  overflow: hidden;
}

#graphiql {
  height: 100vh;
  display: flex;
  flex-direction: column;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.graphiql-form__message {
  flex-grow: 1;
  background-color: #f6f7f8;
  padding: 24px;
  text-align: center;
}

.graphiql-form__logo {
  margin: 24px 0;
}

.graphiql-form {
  background-color: #f7f7f7;
  display: flex;
  padding: 12px;
  align-items: center;
  border-bottom: 1px solid #d0d0d0;
  justify-content: space-between;
  color: #141823;
}

.graphiql-form__label {
  flex-grow: 1;
  display: flex;
}

.graphiql-form__input {
  background: #fafafa;
  border: 1px solid #d0d0d0;
  border-radius: 3px;
  color:#555;
  display: inline-block;
  margin: 0 5px;
  padding: 3px 11px 5px;
  flex-grow: 1;
}

.graphiql-form__submit {
  background: linear-gradient(#f9f9f9, #ececec);
  border: 0;
  border-radius: 3px;
  box-shadow: inset 0 0 0 1px
  rgba(0,0,0,0.20), 0 1px 0 rgba(255,255,255, 0.7), inset 0 1px #fff;
  color: #555;
  cursor: pointer;
  display: inline-block;
  margin: 0 5px;
  padding: 3px 11px 5px;
  text-decoration: none;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 150px;
}